var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('Navbar', {
    attrs: {
      "nav-light": true
    }
  }), _c('section', {
    staticClass: "bg-half bg-primary d-table w-100"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row justify-content-center"
  }, [_c('div', {
    staticClass: "col-lg-12 text-center"
  }, [_c('div', {
    staticClass: "page-next-level"
  }, [_c('h4', {
    staticClass: "title text-white",
    staticStyle: {
      "text-transform": "capitalize"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("list-donor-products")) + " ")])])])])])]), _c('section', {
    staticClass: "section"
  }, [_vm.currentUser ? _c('div', {
    staticClass: "container overflow-hidden"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('div', {
    staticClass: "table-responsive bg-white shadow rounded mt-2"
  }, [_c('table', {
    staticClass: "table mb-0 table-center table-sm",
    staticStyle: {
      "font-size": "10pt"
    }
  }, [_c('thead', {
    staticClass: "bg-light"
  }, [_c('tr', [_c('th', [_vm._v(_vm._s(_vm.$t("index")))]), _c('th', [_vm._v(_vm._s(_vm.$t("trust.type")))]), _c('th', [_vm._v("Status")]), _c('th', [_vm._v(_vm._s(_vm.$t("action")))])])]), !_vm.hibahs ? _c('tbody', [_c('tr', [_c('td', {
    staticClass: "text-center",
    attrs: {
      "colspan": "7"
    }
  }, [_c('h5', {
    staticClass: "mt-5"
  }, [_vm._v(_vm._s(_vm.$t("no-hibah-reg")))])])])]) : _vm._e(), _vm.hibahs ? _c('tbody', [_vm._l(_vm.hibahs, function (item, index) {
    return _c('tr', {
      key: index
    }, [_c('td', [_vm._v(_vm._s(index + 1))]), _c('td', [_vm._v(_vm._s(_vm._f("hibahType")(item.hibah_type_id)))]), item.hibah_type_id != 12 ? _c('td', [_vm._v(" " + _vm._s(_vm._f("hibahStatus")(item.status)) + " ")]) : _vm._e(), item.hibah_type_id == 12 ? _c('td', [_vm._v(" " + _vm._s(_vm._f("hibahStatusonecent")(item.status)) + " ")]) : _vm._e(), item.hibah_type_id == 12 ? _c('td', [_c('div', {
      staticClass: "btn-group-vertical",
      attrs: {
        "role": "group",
        "aria-label": "Vertical button group"
      }
    }, [item.status == 0 ? _c('router-link', {
      staticClass: "btn btn-secondary btn-sm",
      attrs: {
        "tag": "button",
        "to": {
          name: 'trust-onecent.part-d',
          query: {
            trust_id: item.id
          }
        }
      }
    }, [_vm._v(" " + _vm._s(_vm.$t("update")) + " ")]) : _vm._e(), item.status == 2 ? _c('router-link', {
      staticClass: "btn btn-secondary btn-sm",
      attrs: {
        "tag": "button",
        "to": {
          name: 'trust-onecent.part-d',
          query: {
            trust_id: item.id
          }
        }
      }
    }, [_vm._v(" " + _vm._s(_vm.$t("view")) + " ")]) : _vm._e(), item.status == 5 ? _c('router-link', {
      staticClass: "btn btn-secondary btn-sm",
      attrs: {
        "tag": "button",
        "to": {
          name: 'trust-onecent.part-d',
          query: {
            trust_id: item.id
          }
        }
      }
    }, [_vm._v(" " + _vm._s(_vm.$t("print-cert")) + " ")]) : _vm._e()], 1)]) : _vm._e(), item.hibah_type_id != 12 ? _c('td', [_c('div', {
      staticClass: "btn-group-vertical",
      attrs: {
        "role": "group",
        "aria-label": "Vertical button group"
      }
    }, [item.status == 1 ? _c('router-link', {
      staticClass: "btn btn-secondary btn-sm",
      attrs: {
        "tag": "button",
        "to": {
          name: 'trust.part-a',
          query: {
            trust_id: item.id
          }
        }
      }
    }, [_vm._v(" " + _vm._s(_vm.$t("update")) + " ")]) : _vm._e(), item.status == 3 && !item.is_draft_accepted ? _c('router-link', {
      staticClass: "btn btn-secondary btn-sm",
      attrs: {
        "tag": "button",
        "to": {
          name: 'trust.part-draft',
          query: {
            trust_id: item.id
          }
        }
      }
    }, [_vm._v(" " + _vm._s(_vm.$t("draft-approval")) + " ")]) : _vm._e(), item.status == 3 && item.is_draft_accepted ? _c('router-link', {
      staticClass: "btn btn-secondary btn-sm",
      attrs: {
        "tag": "button",
        "to": {
          name: 'trust.part-signpenerima',
          query: {
            trust_id: item.id
          }
        }
      }
    }, [_vm._v(" " + _vm._s(_vm.$t("recipient-verification")) + " ")]) : _vm._e(), item.status == 4 ? _c('router-link', {
      staticClass: "btn btn-secondary btn-sm",
      attrs: {
        "tag": "button",
        "to": {
          name: 'trust.part-i',
          query: {
            trust_id: item.id
          }
        }
      }
    }, [_vm._v(" " + _vm._s(_vm.$t("witness-confirmation")) + " ")]) : _vm._e(), item.status == 5 ? _c('router-link', {
      staticClass: "btn btn-secondary btn-sm",
      attrs: {
        "tag": "button",
        "to": {
          name: 'trust.part-a',
          query: {
            trust_id: item.id
          }
        }
      }
    }, [_vm._v(" " + _vm._s(_vm.$t("amendments2")) + " ")]) : _vm._e(), item.status == 5 ? _c('b-button', {
      staticClass: "btn btn-secondary btn-sm",
      attrs: {
        "tag": "button"
      },
      on: {
        "click": _vm.printCetificate
      }
    }, [_vm._v(_vm._s(_vm.$t("print-cert")) + " ")]) : _vm._e()], 1)]) : _vm._e()]);
  }), _vm.hibahs && _vm.hibahs.length == 0 ? _c('tr', [_c('td', {
    staticClass: "text-center",
    attrs: {
      "colspan": "9"
    }
  }, [_c('h6', {
    staticClass: "mt-5 mb-5"
  }, [_vm._v(" " + _vm._s(_vm.$t("trust.no-hibah")) + " ")])])]) : _vm._e()], 2) : _vm._e()])])])])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-8"
  }, [_c('b-pagination', {
    attrs: {
      "total-rows": _vm.pagination.totalRows,
      "per-page": _vm.pagination.perPage,
      "aria-controls": "my-table"
    },
    on: {
      "change": _vm.pageClicks
    },
    model: {
      value: _vm.pagination.currentPage,
      callback: function ($$v) {
        _vm.$set(_vm.pagination, "currentPage", $$v);
      },
      expression: "pagination.currentPage"
    }
  })], 1), _c('div', {
    staticClass: "col-md-4"
  }, [_c('div', {
    staticClass: "form custom-form"
  }, [_c('div', {
    staticClass: "form-group mb-0"
  }, [_c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.pagination.perPage,
      expression: "pagination.perPage"
    }],
    staticClass: "form-control custom-select",
    attrs: {
      "id": "Sortbylist-job"
    },
    on: {
      "change": [function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.pagination, "perPage", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }, function ($event) {
        return _vm.changePerPage();
      }]
    }
  }, [_c('option', {
    attrs: {
      "value": "10"
    }
  }, [_vm._v("10 " + _vm._s(_vm.$t("per-page")))]), _c('option', {
    attrs: {
      "value": "25"
    }
  }, [_vm._v("25 " + _vm._s(_vm.$t("per-page")))]), _c('option', {
    attrs: {
      "value": "50"
    }
  }, [_vm._v("50 " + _vm._s(_vm.$t("per-page")))])])])])])])])])])]) : _vm._e()]), _c('Footer'), _c('a', {
    directives: [{
      name: "scroll-to",
      rawName: "v-scroll-to",
      value: '#topnav',
      expression: "'#topnav'"
    }],
    staticClass: "btn btn-icon btn-soft-primary back-to-top",
    attrs: {
      "href": "javascript: void(0);",
      "id": "back-to-top"
    }
  }, [_c('arrow-up-icon', {
    staticClass: "icons"
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }